import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'

import infinitySvg from './infinity-symbol.svg'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import '../../assets/css/myAccount.css';

const Addons = (props) => { 

    const [cloudId, setCloudId] = useState("FONUSMOBILE");
    const[loading, setLoading] = useState(false);
    const [cloudUsername, setCloudUsername] = useState("");
    const [cloudPassword, setCloudPassword] = useState("");
    const [redirect, setRedirect] = useState(null);


    useEffect(() => {  
        const encodedUrlString = window.location.href.replace(/\+/g, '%2B');
        const url = new URL(encodedUrlString);
        const username  = url.searchParams.get("cloud_username");
        const password = url.searchParams.get("cloud_password");
        setCloudUsername(username);
        setCloudPassword(password);
    }, []); 
    
    const topUpuser = () => {

        confirmAlert({
            title: 'Are you sure?',
            message: 'This will automatically deduct US$5.00 through the default payment method on your account.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        const payLoad = {
                            cloud_username: cloudUsername,
                            cloud_password: cloudPassword,
                            cloud_id: cloudId,
                            package_name: "Global Texting Package",
                            payment_method: "stripe",
                            currency: "usd",
                            price: 5
                        };
                        
                        axios.post(`${process.env.REACT_APP_API_URL}billing/fonusTopUp`, payLoad)
                        .then(response => {


                            setLoading(false);
                            Toast.success("Package Subscribed Successfully");
                        }).catch(err => {
                            Toast.error("Failed to subscribe Add-On. Please contact support.")
                            setLoading(false);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
        
    }



    

    return (
        <Auxi>
            {redirect}
            <div className = "my-account-page">
                <div className="row outer-container">
                    <h3 className="m-b-10 loader-center">
                        {loading
                            ? <Spinner data="" />
                            : null
                        }
                    </h3>
                    <div className="col-md-12 number-selection-heading">
                        <i className="feather icon-chevron-left" onClick={e => setRedirect(<Redirect to = {"/my_account?cloud_username="+cloudUsername+"&cloud_password="+cloudPassword} />)}> </i>
                        <h1> Add-Ons </h1>
                    </div>

                    <div className="col-md-12" style={{marginTop:"10px"}} onClick={topUpuser}>
                        <div className="add-on-container">
                            <div className="text-container">
                                <h4>Global Texting Package</h4>
                                <p>
                                    Send texts to over 150 countries.<br/>
                                    Charged on a per-SMS basis<br/>
                                    starting at $0.02 per text. This<br/>
                                    package provides a $5 credit.<br/>
                                    For rates, click <span>here</span>
                                </p>
                            </div>
                            
                            <div className="price">
                                <h2>$5</h2>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>

        </Auxi>

    );


}

export default Addons;
